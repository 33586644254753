<template>
  <!-- Traitement phyto et ferti -->
  <div class="general-information-subview">
    <teleport to="#header-action">
      <Btn
        v-if="helperService.userHasPermission('essaievenement_oedit')"
        class="action-btn"
        icon="create"
        color="white"
        hollow
        text="Modifier"
        :to="{
          name: 'essaiExperimentalTaskConditionsTraitementEdit',
          params: {
            id: essaiId,
            tid: task.id
          }
        }"
      />
    </teleport>
  </div>

  <div class="tabs-material-subtitle">
    <Container>
      <div class="tabs-material-subtitle--button">
        <h2>Conditions de traitement</h2>

        <Btn
          color="primary"
          hollow
          text="Exporter la fiche"
          @click="download('pdf', 'evenement', $route.params.tid)"
        />
      </div>
    </Container>
  </div>

  <div class="key-values">
    <KeyValue
      table
      label="Materiel utilisé"
      :value="fiche?.materielutilise"
    />
    <KeyValue
      table
      label="Référence"
      :value="fiche?.reference"
    />
    <KeyValue
      table
      label="Autre"
      :value="fiche?.autre"
    />
  </div>
  <Section class="no-padding-bottom">
    <Container>
      <h3>Contrôle pulvérisateur et ses éléments avant application</h3>
    </Container>
  </Section>
  <div class="key-values">
    <KeyValue
      table
      label="Contrôle pulvérisateur"
      :value="fiche?.controle_pulveristeur?.valeur"
    />
    <KeyValue
      table
      label="Commentaires"
      :value="fiche?.controlepulve_commentaires"
    />
  </div>
  <Section class="no-padding-bottom">
    <Container>
      <h3>Heure</h3>
    </Container>
  </Section>
  <div class="key-values">
    <KeyValue
      table
      label="Heure de début"
      :value="fiche?.heure_debut"
    />
    <KeyValue
      table
      label="Heure de fin"
      :value="fiche?.heure_fin"
    />
  </div>

  <Section class="no-padding-bottom">
    <Container>
      <h3>Stade</h3>
    </Container>
  </Section>
  <div class="key-values">
    <KeyValue
      table
      label="Culture"
      :value="`${fiche?.stade?.code_bbch} - ${fiche?.stade?.designation_court}`"
    />
  </div>

  <Section class="no-padding-bottom">
    <Container>
      <h3>État végétatif</h3>
    </Container>
  </Section>
  <div class="key-values">
    <KeyValue
      table
      label="Culture"
      :value="fiche?.etat_vegetatif_culture"
    />
  </div>

  <Section class="no-padding-bottom">
    <Container>
      <h3>Température</h3>
    </Container>
  </Section>
  <div class="key-values">
    <KeyValue
      table
      label="Début"
      :value="fiche?.temperature_debut ? fiche?.temperature_debut + '°C' : null"
    />
    <KeyValue
      table
      label="Fin"
      :value="fiche?.temperature_fin ? fiche?.temperature_fin + '°C' : null"
    />
  </div>

  <Section class="no-padding-bottom">
    <Container>
      <h3>Hygrométrie</h3>
    </Container>
  </Section>
  <div class="key-values">
    <KeyValue
      table
      label="Début"
      :value="fiche?.hygrometrie_debut ? fiche?.hygrometrie_debut + '%' : null"
    />
    <KeyValue
      table
      label="Fin"
      :value="fiche?.hygrometrie_fin ? fiche?.hygrometrie_fin + '%' : null"
    />
  </div>

  <Section class="no-padding-bottom">
    <Container>
      <h3>Vent</h3>
    </Container>
  </Section>
  <div class="key-values">
    <KeyValue
      table
      label="Vitesse"
      :value="fiche?.vitesse_vent?.valeur"
    />
    <KeyValue
      table
      child
      label="Début"
      :value="fiche?.vitessevent_min ? fiche?.vitessevent_min + ' km/h' : null"
    />
    <KeyValue
      table
      child
      label="Fin"
      :value="fiche?.vitessevent_max ? fiche?.vitessevent_max + ' km/h' : null"
    />
  </div>

  <Section class="no-padding-bottom">
    <Container>
      <h3>Sol</h3>
    </Container>
  </Section>

  <div class="key-values">
    <KeyValue
      table
      label="État surface"
      :value="fiche?.etat_surface_sol?.valeur"
    />
    <KeyValue
      table
      label="Humidité"
      :value="fiche?.humidite_sol?.valeur"
    />
  </div>

  <Section class="no-padding-bottom">
    <Container>
      <h3>Résidus récolte</h3>
    </Container>
  </Section>

  <div class="key-values">
    <KeyValue
      table
      label="État"
      :value="fiche?.residus_recolte?.valeur"
    />
  </div>

  <Section class="no-padding-bottom">
    <Container>
      <h3>Ensoleillement</h3>
    </Container>
  </Section>

  <div class="key-values">
    <KeyValue
      table
      label="État"
      :value="fiche?.ensoleillement?.valeur"
    />
  </div>

  <Section class="no-padding-bottom">
    <Container>
      <h3>Nébulosité</h3>
    </Container>
  </Section>

  <div class="key-values">
    <KeyValue
      table
      label="État"
      :value="fiche?.nebulosite?.valeur"
    />
    <KeyValue
      table
      child
      label="Mesure"
      :value="fiche?.nebulosite_mesure ? fiche?.nebulosite_mesure + '%' : null"
    />
  </div>

  <Section class="no-padding-bottom">
    <Container>
      <h3>Pluie</h3>
    </Container>
  </Section>

  <div class="key-values">
    <KeyValue
      table
      label="Dans les 24h"
      :value="helperService.displayBoolean(fiche?.pluie_24h)"
    />
    <KeyValue
      table
      child
      label="Mesure"
      :value="fiche?.pluie_quantite ? fiche?.pluie_quantite + ' mm' : null"
    />
  </div>

  <Section class="no-padding-bottom">
    <Container>
      <h3>Rosée</h3>
    </Container>
  </Section>

  <div class="key-values">
    <KeyValue
      table
      label="Au moment du traitement"
      :value="fiche?.rosee?.valeur"
    />
  </div>

  <Section class="no-padding-bottom">
    <Container>
      <h3>Pression des buses</h3>
    </Container>
  </Section>

  <div class="key-values">
    <KeyValue
      table
      label="Quantité"
      :value="fiche?.pressionbuses_quantite ? fiche?.pressionbuses_quantite + ' bars' : null"
    />
    <KeyValue
      table
      label="Type de buses"
      :value="fiche?.typebuses"
    />
  </div>

  <Section class="no-padding-bottom">
    <Container>
      <h3>Modalités traitées</h3>
    </Container>
  </Section>

  <div class="key-values">
    <KeyValue
      table
      label="Numéro"
      :value="fiche?.modalites?.map((modality) => modality.ordre).sort((a,b) => a - b).join(', ')"
    />
  </div>

  <Section class="no-padding-bottom">
    <Container>
      <h3>Observations</h3>
    </Container>
  </Section>

  <div class="key-values">
    <KeyValue
      table
      label="Cible"
      :value="fiche?.observations_cible"
    />
    <KeyValue
      table
      label="Traitement"
      :value="fiche?.observations_traitement"
    />
  </div>

  <Section class="no-padding-bottom">
    <Container>
      <h3>Validations traitement</h3>
    </Container>
  </Section>

  <div class="key-values key-values--page-bottom">
    <KeyValue
      table
      label="Trigramme"
      :value="fiche?.validation_trigramme"
    />
    <KeyValue
      table
      label="Date"
      :value="helperService.displayDate(fiche?.validation_date)"
    />
  </div>
</template>

<script>

import KeyValue from '@/components/form/KeyValue.vue'
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'ConditionsTraitementSubview',
  components: {
    Container,
    Section,
    Btn,
    KeyValue,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      essaiId: this.$route.params.id,
      fiche: {},
    }
  },

  emits: ['set-editable'],

  mounted() {
    this.getFiche()
  },

  methods: {
    async getFiche() {
      this.fiche = await this.taskService.getFiche(this.$route, 'conditions_traitement')
    },
    download(type, ressource, rid) {
      const endpoint = `taches/type/${type}/ressource/${ressource}/${rid}/conditions-traitement`
      const filename = `evenement_${rid}_conditions_traitement`
      this.fileService.downloadFile(endpoint, filename, type)
    },
  },
}
</script>

<style lang="scss" scoped>
.tabs-material-subtitle--button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
